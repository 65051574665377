<template>
    <main-container :icon="$route.meta.icon" :title="$route.meta.title">

            <EmcTableItems
                :headers="headers"
                v-bind:params.sync="params"
                v-bind:collections.sync="collections"
                v-bind:meta.sync="meta"
                v-bind:itemDelete.sync="itemDelete"
                v-bind:itemEdit.sync="model"
                :loading="loading"
            />

            <template v-slot:tools>
                <EmcButtonIconCreate
                    @update:show="(value) => showForm = value"
                />
            </template>

            <FormRegisterType
                :show="showForm"
                @update:show="(value) => showForm = value"
                :model="model"
                @onCompleted="getData()"
            />

            <!-- Modal usado para mensagem e ação de exclusão -->
            <emc-alert-modal :text="text" v-bind:show.sync="show">
                <template v-slot:actions>
                    <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                </template>
            </emc-alert-modal>
        </main-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormRegisterType from './FormRegisterType.vue';

export default {
    name: 'FinancialPostingTypes',
    data () {
        return {
            headers: [
                { text: 'Nome', value: 'name', sortable: false},
                { text: 'Criado em', value: 'created_at', sortable: false, type: 'date' },
                { text: 'Icone', value: 'icon', sortable: false, type: 'icon' },
                { text: 'Categoria para receita', value: 'is_receipt', sortable: false, type: 'boolean', align: 'center' },
                { text: 'Status', value: 'active', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false}
            ],
            params: {},
            meta: {},
            collections: [],
            itemDelete: {},
            model: { active: 1 , is_receipt: 1 },
            loading: false,
            showForm: false,
            show: false,
            text: null,
            deleting: false,
            message: null,
            error: false
        }
    },
    components: {
        FormRegisterType
    },
    methods: {
        ...mapActions('finance', ['ActionFinancialPostingTypesIndex', 'ActionFinancialPostingTypesDestroy']),
        getData() {

            this.showForm = false;
            this.loading = !this.loading

            this.ActionFinancialPostingTypesIndex(this.params)
                .then((r) => {
                    this.collections = r.data
                    this.meta = r.meta
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteItem() {
            this.deleting = true;
            this.message = null;

            this.ActionFinancialPostingTypesDestroy({ id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }
    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        },
        model(item_new, item_old){
            if(item_new !== item_old && (this.model && this.model.id))
            {
                this.showForm = true
                this.message = null

            } else if(Object.keys(this.model).length === 0){
                this.showForm = false;
            }
        },
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir " + item_new.name + "?"
            } else {
                this.show = false
                this.text = null
            }
        },
        showForm(val) {
            if(!val) {
                this.model = { active: 1 , is_receipt: 1 }
            }
        },
    }
}
</script>