var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title:
        _vm.model && _vm.item.id
          ? "Atualizar categoria"
          : "Cadastrar categoria",
      icon: "mdi-sitemap",
      show: _vm.dialog,
      maxWidth: "30%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-1" },
              [
                _vm.message
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              color: "warning",
                              prominent: "",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Nome*",
                    md: "12",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.name
                  },
                  model: {
                    value: _vm.item.name,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "name", $$v)
                    },
                    expression: "item.name"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Icone",
                    md: "6",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.icon
                  },
                  model: {
                    value: _vm.item.icon,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "icon", $$v)
                    },
                    expression: "item.icon"
                  }
                }),
                _c("EmcButtonColor", {
                  attrs: { md: "6" },
                  model: {
                    value: _vm.item.color,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "color", $$v)
                    },
                    expression: "item.color"
                  }
                })
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("EmcFormDecimalTextField", {
                  attrs: {
                    "append-icon": "mdi-percent-outline",
                    label: "Imposto",
                    hint:
                      "Será utilizado para calcular o imposto que será pago ",
                    outlined: "",
                    md: "4",
                    disabled: !_vm.item.is_receipt,
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.color
                  },
                  model: {
                    value: _vm.item.paid_taxation,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "paid_taxation", $$v)
                    },
                    expression: "item.paid_taxation"
                  }
                }),
                _c("EmcFormDecimalTextField", {
                  attrs: {
                    "append-icon": "mdi-percent-outline",
                    label: "Juros",
                    hint:
                      "Será utilizado para calcular pagamentos recebidos em atraso",
                    outlined: "",
                    md: "4",
                    disabled: !_vm.item.is_receipt,
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.color
                  },
                  model: {
                    value: _vm.item.interest_charges,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "interest_charges", $$v)
                    },
                    expression: "item.interest_charges"
                  }
                }),
                _c("EmcFormDecimalTextField", {
                  attrs: {
                    "append-icon": "mdi-percent-outline",
                    label: "Mora",
                    hint:
                      "Será utilizado para calcular pagamentos recebidos em atraso",
                    outlined: "",
                    md: "4",
                    disabled: !_vm.item.is_receipt,
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.color
                  },
                  model: {
                    value: _vm.item.late_payment_percent,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "late_payment_percent", $$v)
                    },
                    expression: "item.late_payment_percent"
                  }
                })
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "5" } },
                  [
                    _c(
                      "v-sheet",
                      { staticClass: "px-2" },
                      [
                        _c("v-switch", {
                          attrs: {
                            inset: "",
                            label: "Receita?",
                            "hide-details": "",
                            color: "green"
                          },
                          model: {
                            value: _vm.item.is_receipt,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "is_receipt", $$v)
                            },
                            expression: "item.is_receipt"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("EmcFormTextArea", {
                  attrs: {
                    label: "Descrição",
                    outlined: "",
                    md: "12",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.description
                  },
                  model: {
                    value: _vm.item.description,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "description", $$v)
                    },
                    expression: "item.description"
                  }
                }),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "v-sheet",
                      { staticClass: "px-2" },
                      [
                        _c("v-switch", {
                          attrs: {
                            inset: "",
                            label: _vm.item.active ? "Ativo" : "Inativo",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.item.active,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "active", $$v)
                            },
                            expression: "item.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }